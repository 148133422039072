import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Paper, Grid, Button, Box, Typography, Divider, ButtonBase, Link,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from "@material-ui/styles";

import {
  formatPosition, formatDate
} from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import RemoveDialog from '../RemoveDialog';
import { getIsAdmin, getPosition } from '../common/selectors';
import { useTranslation } from '../LocalizationProvider';
import moment from 'moment';
import { api_call, console_log, empty, getDistanceFromMiles, getSpeedFromMiles, isGpsMT2VProtocolDevice, is_null } from '../helpers/untils';
import CreditLogsModal from '../settings/CreditLogsModal';
import { devicesActions, layoutActions } from '../store';
import NotificationModal from '../settings/NotificationModal';
import TuroSettingModal from '../settings/TuroSettingModal';
import TollSettingModal from '../settings/TollSettingModal';
import MaintSettingModal from '../settings/MaintSettingModal';
import { getFirstWordFromString, sleep } from '../helpers/misc';
import CommandSendDialog from './CommandSendDialog';
import MT2VCommandSendDialog from './MT2VCommandSendDialog';
import MT2VNotificationModal from '../settings/MT2VNotificationModal';
import { DEVICE_TYPE, MOVING_SPEED_MIN, MT2V_DC_VOLT_MAX, TESLA_PERMISSION_URL } from '../config/constant';
import { linearProgressClasses, Stack } from '@mui/material';
import IncomeExpenseModal from '../settings/modals/IncomeExpenseModal';
import FuelCalibrateModal from '../settings/FuelCalibrateModal';
import TeslaCommandSendDialog from './TeslaCommandSendDialog';
import CommandSendDialogWrapper from './CommandSendDialogWrapper';
import GoogleGeolocationAddressBox from '../components/GoogleGeolocationAddressBox';

var user;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '300px',
  },
  ...theme.palette.colors,
  listItemContainer: {
    // maxWidth: '280px',
    '& .MuiListItem-root': {
      paddingTop: '4px',
      paddingBottom: '4px',
    }
  },
  creditValue: {
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: 'rgba(76, 175, 80, 0.12)',
    border: '2px solid #4CAF50'
  },
  modalBottomButton: {
    minWidth: '40px'
  },
  modalBottomControlButton: {
    width: '100%',
    flex: 1,
    background: 'rgba(76, 175, 80, 0.01)',
    border: '2px solid rgba(76, 175, 80, 1)',
  },
  lineItem: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '4px',
    paddingBottom: '4px',
    width: '100%'
  },
  itemLabel: {
    width: '105px'
  },
  gridLineList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '5px'
  },
  gridLineItem: {
    flex: 1,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // backgroundColor: 'red'
  },
  gridVerticalLine: {
    height: '20px',
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme, type, width }) => ({
  height: 20,
  width: width ?? 120,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: type === "green" ? "rgb(186, 224, 188)" : type === "orange" ? "rgb(255, 215, 182)" : type === "red" ? "rgb(255, 196, 191)" : "rgb(177, 177, 196)",
  },
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    backgroundColor: type === "green" ? "#4CAF50" : type === "orange" ? "#f48225" : type === "red" ? "#e52121" : "#333366"
  },
}));

const GasGridLines = () => {
  const classes = useStyles();
  const lineArr = [0, 1, 2, 3, 4, 5, 6, 7]
  return (
    <>
      <div className={classes.gridLineList}>
        {
          lineArr.map((item, index) => {
            return (
              <div key={index} className={classes.gridLineItem}>
                {
                  (index === 3) ? (
                    <div className={classes.gridVerticalLine} style={{ height: '26px' }}></div>
                  ) : (index < 7) ? (
                    <div className={classes.gridVerticalLine}></div>
                  ) : (
                    <></>
                  )
                }
              </div>
            )
          })
        }
      </div>
    </>
  )
}

const GasLineBar = (props) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ display: 'block', width: '100%', position: 'relative', width: '120px', height: '20px' }}>
        <div style={{ display: 'block', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
          {
            props.children
          }
        </div>
        <div style={{ display: 'block', position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
          <GasGridLines />
        </div>
      </div>
    </>
  )
}

const StatusView = ({
  deviceId, onShowDetails, onShowHistory, onShareLink, onEditClick, lat, lng, onLogClick, onClosePopup, history, userDeviceItem
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const t = useTranslation();

  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const device = useSelector((state) => state.devices.items[deviceId]);

  const position = useSelector(getPosition(deviceId));

  //console_log("StatusView::::::", userDeviceItem);

  user = useSelector((state) => state.session.user);

  const isAdmin = useSelector(getIsAdmin);

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');

  const handleClick = (e) => {
    e.preventDefault();
    onShowDetails(position.id);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    onEditClick(deviceId);
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    onClosePopup(deviceId)
  };

  const handleLog = async (e) => {
    e.preventDefault();

    dispatch(devicesActions.setSelectedDeviceName(device.name));
    dispatch(devicesActions.setSelectedDeviceId(device.id));
    await sleep(20)

    onLogClick(deviceId);
  };

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [commandsDetail, setCommandsDetail] = useState();
  const [coHostDeviceDetail, setCoHostDeviceDetail] = useState(null);

  const handleSendCommand = async () => {
    var url = `/api/userDeviceCommandsDetail/${deviceId}`;
    const response = await api_call(url);
    if (response.ok) {
      const apiRes = await response.json()
      setCommandsDetail(apiRes.commands);
      setCoHostDeviceDetail(apiRes.coHostDeviceRow)
    }
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  ////////////////////////////////credit log modal////////////////////////////////
  const [deviceItem, setDeviceItem] = useState({});
  const showCreditLogsPage = (item) => {
    setDeviceItem(item);
    setCreditModalOpen(true)
  }
  const [creditModalOpen, setCreditModalOpen] = useState(false);

  let sendingSingal = false;
  const OnClickSingal = async () => {
    if (sendingSingal) {
      return false;
    }
    sendingSingal = true;
    console_log("OnClickSingal::::");
    let value = "Iccid"
    let response = await api_call(`/api/command?command=${value}&deviceId=${device.id}&userId=${user.id}`);
    if (response.ok) {
      console_log("Sent Iccid Command.");
    }
    value = "Rssid"
    response = await api_call(`/api/command?command=${value}&deviceId=${device.id}&userId=${user.id}`);
    if (response.ok) {
      console_log("Sent Rssid Command.");
    }
    sendingSingal = false;
  }

  ////////////////////////////////notification modal////////////////////////////////
  const showNotificationModal = (item) => {
    setDeviceItem(item);
    setNotificationModalOpen(true)
  }
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  ////////////////////////////////end notification modal////////////////////////////

  ////////////////////////////////turo setting modal////////////////////////////////
  const showTuroSettingModal = (item) => {
    setDeviceItem(item);
    setTuroSettingModalOpen(true)
  }
  const [turoSettingModalOpen, setTuroSettingModalOpen] = useState(false);
  ////////////////////////////////end turo setting modal////////////////////////////

  ////////////////////////////////toll setting modal////////////////////////////////
  const showTollSettingModal = (item) => {
    setDeviceItem(item);
    setTollSettingModalOpen(true)
  }
  const [tollSettingModalOpen, setTollSettingModalOpen] = useState(false);
  ////////////////////////////////end toll setting modal////////////////////////////

  ////////////////////////////////maint setting modal////////////////////////////////
  const showMaintSettingModal = (item) => {
    setDeviceItem(item);
    setMaintSettingModalOpen(true)
  }
  const [maintSettingModalOpen, setMaintSettingModalOpen] = useState(false);
  ////////////////////////////////end maint setting modal////////////////////////////

  const [fuelCalibrateModalOpen, setFuelCalibrateModalOpen] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////
  const defaultSignalText = "Signal"
  const [signalText, setSignalText] = useState(defaultSignalText);

  const checkEmnifyConnectivity = async () => {
    const url = '/api/devices/check-emnify-connectivity'
    const data = { device_id: deviceId }
    const response = await api_call(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const resData = await response.json();
      if (resData['error']) {

      } else {
        const operatorName = resData['operatorName']
        const operatorNameFristWord = getFirstWordFromString(operatorName)
        setSignalText(operatorNameFristWord)
        return true
      }
    } else {

    }
    setSignalText(defaultSignalText)
  }
  useEffect(() => {
    checkEmnifyConnectivity()
  }, [deviceId])

  const getSingalPercent = () => {
    let deviceSignal = device.signal
    if (deviceSignal) {
      if (deviceSignal > 31) {
        deviceSignal = 31
      }
    } else {
      deviceSignal = 0
    }
    const percent = 100 * deviceSignal / 31
    const percentStr = percent.toFixed(0)
    return percentStr
  }

  const [commandCalling, setCommandCalling] = useState({});
  const onClickCommandButton = async (value, deviceId) => {
    if (commandCalling[value]) {
      return false
    }

    const callingStatus = {}
    callingStatus[value] = true
    setCommandCalling({ ...commandCalling, ...callingStatus })
    const response = await api_call(`/api/command?command=${value}&deviceId=${deviceId}&userId=${user.id}`);
    if (response.ok) {
      console.log("Sent Command.");
    }
    callingStatus[value] = false
    setCommandCalling({ ...commandCalling, ...callingStatus })
  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  // const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    //console.log("status view::: changes")
    dispatch(layoutActions.closeAllAppModal());
  }, [deviceId]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////// battery function ///////////////////////////////////////
  const getDeviceBatteryLevel = (crop = false) => {
    let level = 0
    if (device && device?.mt2v_dc_volt > 0) {
      level = device?.mt2v_dc_volt
    }
    if (crop) {
      level = Math.min(level, 100)
    }
    level = Math.floor(Number(level))
    return level
  }

  const getBatteryLevelColor = () => {
    let color = "default"
    const level = getDeviceBatteryLevel(true)
    if (level >= 20) {
      color = "green"
    } else {
      color = "red"
    }
    return color
  }

  const getDeviceVoltage = (crop = false) => {
    let volt = 0
    if (device && device?.mt2v_dc_volt > 0) {
      volt = device?.mt2v_dc_volt
    }
    if (crop) {
      volt = Math.min(volt, MT2V_DC_VOLT_MAX)
    }
    return volt
  }
  const getBatteryColor = () => {
    let color = "default"
    const volt = getDeviceVoltage(true)
    if (volt >= 14.4) {
      color = "green"
      return color
    }

    if (volt >= 12.5 && !device.acc) {
      color = "green"
    }
    else if (volt <= 13 && device.acc && device.speed >= MOVING_SPEED_MIN) {
      color = "orange"
    }

    if (volt < 12) {
      color = "red"
    }
    return color
  }

  const getFuelPercent = () => {
    if (!is_null(device.minFuel) && !is_null(device.maxFuel)) {
      const a0 = (1 / 3) * (4 * device.minFuel - device.maxFuel)
      if (device.maxFuel - a0 !== 0) {
        let p = 100 * (device.fuel - a0) / (device.maxFuel - a0)
        if (p < 0) {
          p = 0
        }
        if (p > 100) {
          p = 100
        }
        return p
      }
    }
    return null
  }

  const onClickFuelBar = (item) => {
    setDeviceItem(item);
    setFuelCalibrateModalOpen(true)
  }

  const renderBatteryGasBar = () => {
    return (
      <>
        <Stack direction={`column`} spacing={1} sx={{ width: '100%', pt: 1 }}>
          {
            (device.deviceType === DEVICE_TYPE.TESLA) ? (
              <>
                <Stack className={classes.lineItem} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                  <Box sx={{ flex: 1 }}>
                    <Stack className={classes.itemLabel} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={0}>
                      <Typography variant="body1">
                        Bat:
                      </Typography>
                      <Typography variant="body1">
                        <span style={{ fontSize: '0.9em' }}>{`${getDeviceBatteryLevel(false)} %`}</span>
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={0.5} sx={{ width: '100%' }}>
                      <BorderLinearProgress
                        variant="determinate"
                        type={getBatteryLevelColor()}
                        value={getDeviceBatteryLevel(true)}
                        width={(device.speed > 0) ? 100 : null}
                      />
                      {
                        (device.speed > 0) ? (
                          <><DirectionsRunIcon /></>
                        ) : (<></>)
                      }
                    </Stack>
                  </Box>
                </Stack>
              </>
            ) : (isGpsMT2VProtocolDevice(device.uniqueId)) ? (
              <>
                <Stack className={classes.lineItem} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                  <Box sx={{ flex: 1 }}>
                    <Stack className={classes.itemLabel} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={0}>
                      <Typography variant="body1">
                        Bat:
                      </Typography>
                      <Typography variant="body1">
                        <span style={{ fontSize: '0.9em' }}>{`${getDeviceVoltage(false)} v`}</span>
                      </Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={0.5} sx={{ width: '100%' }}>
                      <BorderLinearProgress
                        variant="determinate"
                        type={getBatteryColor()}
                        value={getDeviceVoltage(true) / MT2V_DC_VOLT_MAX * 100}
                        width={(device.acc && device.speed > MOVING_SPEED_MIN) ? 100 : null}
                      />
                      {
                        (device.acc && device.speed > MOVING_SPEED_MIN) ? (
                          <><DirectionsRunIcon /></>
                        ) : (<></>)
                      }
                    </Stack>
                  </Box>
                </Stack>

                {
                  (userDeviceItem.measure_type === 'gas') ? (
                    <>
                      {
                        (true || !is_null(getFuelPercent())) ? (
                          <Stack className={classes.lineItem} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                            <Box sx={{ flex: 1 }}>
                              <Stack className={classes.itemLabel} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={0}>
                                <Typography variant="body1">
                                  Gas:
                                </Typography>
                                {
                                  (!is_null(getFuelPercent())) ? (
                                    <>
                                      <Typography variant="body1">
                                        <span style={{ fontSize: '0.9em' }}>{`${getFuelPercent().toFixed(0)} %`}</span>
                                      </Typography>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </Stack>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <ButtonBase disableRipple={true} onClick={() => onClickFuelBar(device)}>
                                <GasLineBar>
                                  <BorderLinearProgress variant="determinate" type="default" value={getFuelPercent() ?? 0} />
                                </GasLineBar>
                              </ButtonBase>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  ) : (
                    <></>
                  )
                }
              </>
            ) : (
              <>
                {
                  (isAdmin) ? (
                    <>
                      {
                        (true || !is_null(getFuelPercent())) ? (
                          <Stack className={classes.lineItem} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                            <Box sx={{ flex: 1 }}>
                              <Stack className={classes.itemLabel} direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={0}>
                                <Typography variant="body1">
                                  Fuel:
                                </Typography>
                                {
                                  (!is_null(getFuelPercent())) ? (
                                    <>
                                      <Typography variant="body1">
                                        <span style={{ fontSize: '0.9em' }}>{`${getFuelPercent().toFixed(0)} %`}</span>
                                      </Typography>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </Stack>
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <ButtonBase disableRipple={true} onClick={() => onClickFuelBar(device)}>
                                <GasLineBar>
                                  <BorderLinearProgress variant="determinate" type="default" value={getFuelPercent() ?? 0} />
                                </GasLineBar>
                              </ButtonBase>
                            </Box>
                          </Stack>
                        ) : (
                          <></>
                        )
                      }
                    </>
                  ) :
                    (<></>)
                }
              </>
            )
          }
        </Stack>
      </>
    )
  }

  const [incomeExpenseModalOpen, setIncomeExpenseModalOpen] = useState(false);
  const onClickPL = (item) => {
    setDeviceItem(item);
    setIncomeExpenseModalOpen(true)
  }

  return (
    <>
      {
        (device) ? (
          <>
            <div className='status-view-popup' style={{ maxHeight: 'calc(100vh - 185px)', overflowY: 'auto' }}>
              <Paper className={classes.paper} elevation={0} square>
                <Grid container direction="column">
                  <Grid item>
                    <List>
                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary={device.name} />
                        <ListItemSecondaryAction>
                          <span className={classes.creditValue} title={`Click here to show credit logs or make paypal subscription`} onClick={() => { showCreditLogsPage(device) }}>$ {device.credit}</span>
                        </ListItemSecondaryAction>
                      </ListItem>

                      {
                        (device.deviceType === DEVICE_TYPE.MOOVETRAX) ? (
                          <>
                            <ListItem classes={{ container: classes.listItemContainer }}>
                              <ListItemText primary={signalText} />
                              <div className=''>
                                {(moment().diff(moment(device.lastConnect), "seconds") < 600) && <ListItemSecondaryAction style={{ cursor: 'pointer' }} onClick={(e) => OnClickSingal()}>
                                  <div className='valign'>
                                    {
                                      device.signal === 0 && <SignalCellular0BarIcon /> ||
                                      device.signal <= 8 && <SignalCellular1BarIcon /> ||
                                      device.signal <= 16 && <SignalCellular2BarIcon /> ||
                                      device.signal <= 24 && <SignalCellular3BarIcon /> ||
                                      device.signal <= 31 && <SignalCellular4BarIcon />
                                    }
                                    <span className='valign'>
                                      {/* <span className={classes[getStatusColor(device.status)]}>{device.status}</span> */}
                                      <span className='vertical-text'>{getSingalPercent()}</span>
                                      <span>{`%`}</span>
                                    </span>
                                  </div>
                                </ListItemSecondaryAction>}
                              </div>
                            </ListItem>
                          </>
                        ) : (
                          <></>
                        )
                      }

                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary={<a href={TESLA_PERMISSION_URL} target='_blank' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Last Position</a>} />
                        <ListItemSecondaryAction>
                          {formatDate(device.lastPosition)}
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary="Last Connect" />
                        <ListItemSecondaryAction>
                          {formatDate(device.lastConnect)}
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem classes={{ container: classes.listItemContainer }}>
                        {/* <ListItemText primary="Address" /> */}
                        <ListItemSecondaryAction>
                          <GoogleGeolocationAddressBox
                            lat={lat}
                            lng={lng}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary={t('positionSpeed')} />
                        {
                          (device.deviceType === DEVICE_TYPE.SMARTCAR || device.deviceType === DEVICE_TYPE.TESLA) ? (
                            <>
                              <ListItemSecondaryAction>
                                {getSpeedFromMiles(position.speed, device.distance_unit)}
                              </ListItemSecondaryAction>
                            </>
                          ) : (
                            <>
                              {
                                (moment().diff(moment(device.lastPosition), "seconds") < 5 * 60) ? (
                                  <ListItemSecondaryAction>
                                    {getSpeedFromMiles(position.speed, device.distance_unit)}
                                  </ListItemSecondaryAction>
                                ) : (
                                  <ListItemSecondaryAction>
                                    {getSpeedFromMiles(0, device.distance_unit)}
                                  </ListItemSecondaryAction>
                                )
                              }
                            </>
                          )
                        }

                      </ListItem>
                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary="Odometer" />
                        <ListItemSecondaryAction>
                          {getDistanceFromMiles(position.attributes.distance, device.distance_unit)}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary={t('positionDirection')} />
                        <ListItemSecondaryAction>
                          {formatPosition(position.direction, 'direction', t)}
                        </ListItemSecondaryAction>
                      </ListItem>

                      <>
                        {
                          (userDeviceItem?.measure_type === 'gas') ? (
                            <></>
                          ) : (
                            <>
                              {
                                renderBatteryGasBar()
                              }
                            </>
                          )
                        }
                      </>

                      {/* <div className='hidden d-none temporary-hidden'>
                        <ListItem classes={{ container: classes.listItemContainer }}>
                          <ListItemText primary="Door" />
                          <ListItemSecondaryAction>
                            {device.door ? "Closed" : "Open"}
                          </ListItemSecondaryAction>
                        </ListItem>
                      </div> */}

                      {/* <div className='mt-2'></div> */}
                      <Grid container>
                        {user.id !== 0 &&
                          <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={() => { onShareLink(device.id) }}>Share</Button>
                          </Grid>}

                        <Grid item>
                          <Button className={classes.modalBottomButton} color="secondary" onClick={() => { onShowHistory(device.id) }}>Replay</Button>
                        </Grid>
                        <Grid item>
                          <Button className={classes.modalBottomButton} color="secondary" onClick={handleSendCommand}>Control</Button>
                        </Grid>
                        <Grid item>
                          <Button className={classes.modalBottomButton} color="secondary" onClick={() => showNotificationModal(device)}>Notify</Button>
                        </Grid>
                        <Grid item>
                          <Button className={classes.modalBottomButton} color="secondary" onClick={() => { window.open(`https://www.google.com/maps/dir/your+location/${position.latitude},${position.longitude}/@${position.latitude},${position.longitude},17z`, '_blank'); }}>Nav</Button>
                        </Grid>

                        {user.id !== 0 && <>

                          {empty(userDeviceItem?.co_host_device) && <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={handleEditClick}>Edit{device?.co_host_device}</Button>
                          </Grid>}
                          {user.administrator && <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={handleRemove}>Del</Button>
                          </Grid>}
                          <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={handleLog}>Log</Button>
                          </Grid>

                          {/* <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={() => showTuroSettingModal(device)}>Turo</Button>
                          </Grid> */}
                          <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={() => showTollSettingModal(device)}>Toll</Button>
                          </Grid>
                          <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={() => showMaintSettingModal(device)}>Maint</Button>
                          </Grid>
                          {!user.administrator && <Grid item>
                            <Button className={classes.modalBottomButton} color="secondary" onClick={() => onClickPL(device)}>P&L</Button>
                          </Grid>}
                        </>}
                      </Grid>

                      <Box className="control-box" sx={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Button className={classes.modalBottomControlButton} color="secondary" disabled={commandCalling['Unlock']} onClick={() => { onClickCommandButton("Unlock", device.id) }}>Unlock</Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button className={classes.modalBottomControlButton} color="secondary" disabled={commandCalling['Lock']} onClick={() => { onClickCommandButton("Lock", device.id) }}>Lock</Button>
                          </Grid>
                        </Grid>
                      </Box>

                      <>
                        {
                          (userDeviceItem?.measure_type !== 'gas') ? (
                            <></>
                          ) : (
                            <>
                              {
                                renderBatteryGasBar()
                              }
                            </>
                          )
                        }
                      </>

                    </List>
                  </Grid>
                </Grid>
              </Paper>
              <RemoveDialog open={removeDialogShown} endpoint="devices" itemId={deviceId} onResult={handleRemoveResult} />

              <>
                <CommandSendDialogWrapper
                  selectedValue={selectedValue}
                  open={open}
                  onClose={handleClose}
                  deviceId={deviceId}
                  commandsDetail={commandsDetail}
                  setCommandsDetail={setCommandsDetail}
                  history={history}
                  coHostDeviceDetail={coHostDeviceDetail}
                  setCoHostDeviceDetail={setCoHostDeviceDetail}
                  device={device}
                  userDeviceItem={userDeviceItem}
                >
                  <>
                    {
                      (isGpsMT2VProtocolDevice(device.uniqueId)) ? (
                        <>
                          <MT2VCommandSendDialog
                            selectedValue={selectedValue}
                            open={open}
                            onClose={handleClose}
                            deviceId={deviceId}
                            commandsDetail={commandsDetail}
                            setCommandsDetail={setCommandsDetail}
                            history={history}
                            coHostDeviceDetail={coHostDeviceDetail}
                            setCoHostDeviceDetail={setCoHostDeviceDetail}
                            device={device}
                            userDeviceItem={userDeviceItem}
                          />
                        </>
                      ) : (device.deviceType === DEVICE_TYPE.TESLA) ? (
                        <>
                          <TeslaCommandSendDialog
                            selectedValue={selectedValue}
                            open={open}
                            onClose={handleClose}
                            deviceId={deviceId}
                            commandsDetail={commandsDetail}
                            setCommandsDetail={setCommandsDetail}
                            history={history}
                            coHostDeviceDetail={coHostDeviceDetail}
                            setCoHostDeviceDetail={setCoHostDeviceDetail}
                            device={device}
                            userDeviceItem={userDeviceItem}
                          />
                        </>
                      ) : (
                        <>
                          <CommandSendDialog
                            selectedValue={selectedValue}
                            open={open}
                            onClose={handleClose}
                            deviceId={deviceId}
                            commandsDetail={commandsDetail}
                            setCommandsDetail={setCommandsDetail}
                            history={history}
                            coHostDeviceDetail={coHostDeviceDetail}
                            setCoHostDeviceDetail={setCoHostDeviceDetail}
                            device={device}
                            userDeviceItem={userDeviceItem}
                          />
                        </>
                      )
                    }
                  </>
                </CommandSendDialogWrapper>
              </>

              <>
                <CreditLogsModal
                  open={creditModalOpen}
                  setOpen={setCreditModalOpen}
                  item={deviceItem}
                  setItem={setDeviceItem}
                />
              </>

              {
                (isGpsMT2VProtocolDevice(device.uniqueId)) ? (
                  <>
                    <MT2VNotificationModal
                      open={notificationModalOpen}
                      setOpen={setNotificationModalOpen}
                      deviceItem={deviceItem}
                      setDeviceItem={setDeviceItem}
                    />
                  </>
                ) : (
                  <>
                    <NotificationModal
                      open={notificationModalOpen}
                      setOpen={setNotificationModalOpen}
                      deviceItem={deviceItem}
                      setDeviceItem={setDeviceItem}
                    />
                  </>
                )
              }

              <>
                <TuroSettingModal
                  open={turoSettingModalOpen}
                  setOpen={setTuroSettingModalOpen}
                  deviceItem={deviceItem}
                  setDeviceItem={setDeviceItem}
                  history={history}
                />
              </>

              <>
                <TollSettingModal
                  open={tollSettingModalOpen}
                  setOpen={setTollSettingModalOpen}
                  deviceItem={deviceItem}
                  setDeviceItem={setDeviceItem}
                  history={history}
                />
              </>

              <>
                <MaintSettingModal
                  open={maintSettingModalOpen}
                  setOpen={setMaintSettingModalOpen}
                  deviceItem={deviceItem}
                  setDeviceItem={setDeviceItem}
                  history={history}
                  onClickPL={() => onClickPL(device)}
                />
              </>

              <>
                <IncomeExpenseModal
                  open={incomeExpenseModalOpen}
                  setOpen={setIncomeExpenseModalOpen}
                  deviceItem={deviceItem}
                  setDeviceItem={setDeviceItem}
                  history={history}
                />
              </>

              <>
                <FuelCalibrateModal
                  open={fuelCalibrateModalOpen}
                  setOpen={setFuelCalibrateModalOpen}
                  deviceItem={deviceItem}
                  setDeviceItem={setDeviceItem}
                  history={history}
                />
              </>

            </div>
          </>) :
          (<></>)
      }
    </>

  );
};

export default StatusView;
