import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, Checkbox, Box, Link, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';
import VisibilityOffIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import moment from 'moment';
import { api_call, console_log, empty, get_utc_timestamp, get_utc_timestamp_ms } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { DEVICE_TYPE, MAX_CREDIT_AMOUNT, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import PayPalBtn from '../components/PayPalBtn';
import { closePaypalBanner, setSettingPersist } from '../helpers/misc';
import EscrowLogsModal from './EscrowLogsModal';

const itemType = "credit"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1024px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const modalInnerStyle = {
  position: 'relative',
  width: '100%',
  maxHeight: 'calc(100vh - 160px)',
  overflowY: 'auto'
};

const useStyles = makeStyles((theme) => ({
  paypalBtnBox: {
    position: 'relative',
    display: 'block',
  },
  paypalHideBtn: {
    right: 0,
    cursor: 'pointer',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '4px',
    paddingBottom: '10px',
    marginLeft: '2px',
  },
}));

const CreditLogsTable = (props) => {
  const { selectedDevice, setDevice, updateTimestamp, setUpdateTimestamp, paypalSubscriptionDetail, setPaypalSubscriptionDetail, logItems, setLogItems, escrowUser, setEscrowUser } = props;

  const user = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const PageSize = 5;

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(logItems.slice(firstPageIndex, lastPageIndex))
  }, [currentPage]);

  useEffectAsync(async () => {
    var url = `/api/creditLogsByDeviceId/` + selectedDevice['id'];
    const response = await api_call(url);
    let t_items = []
    if (response.ok) {
      const creditLogsApiRes = await response.json()
      console_log("creditLogsApiRes::::", creditLogsApiRes)
      t_items = creditLogsApiRes['log_list']
      setItems(t_items);

      const device = creditLogsApiRes['device']
      if (device) {
        setDevice(device)
      }

      const escrow_user = creditLogsApiRes['escrow_user']
      if (!empty(escrow_user)) {
        setEscrowUser(escrow_user)
      } else {
        setEscrowUser({})
      }
    }
    setLogItems(t_items)
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(t_items.slice(firstPageIndex, lastPageIndex));
  }, [updateTimestamp]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Added by</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>When</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (currentTableData.length > 0) ? (
                currentTableData.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.from_name ?? 'Owner'}</TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell>{item.to_name}</TableCell>
                    <TableCell>{item.device_serial_number}</TableCell>
                    <TableCell>{item.to_balance}</TableCell>
                    <TableCell>{moment(item.createdAt).format('LLL')}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow key={`no-data`}>
                  <TableCell colSpan={7} align="center">No log data</TableCell>
                </TableRow>
              )
            }

          </TableBody>
        </Table>
        <div style={{ marginTop: '5px' }}></div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={PageSize}
          totalCount={logItems.length}
          onPageChange={page => setCurrentPage(page)}

        />
      </TableContainer>
    </>
  )
}

const PaypalPaymentBox = (props) => {
  const { open, setOpen, item, setItem, plan_id = "", updateTimestamp, setUpdateTimestamp } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.user);

  const paypalSubscribe = (data, actions) => {
    console.log("data, actions:::", data, actions)
    return actions.subscription.create({
      'plan_id': plan_id,
      'custom_id': `${user['id']}_${item['id']}_${itemType}`
    });
  };

  const paypalOnError = (err) => {
    console.log("Error")
  }

  const paypalOnApprove = (data, actions) => {
    console_log("Payapl approved:::data, actions", data, actions)
    // Capture the funds from the transaction
    return actions.subscription.get().then(function (details) {
      // Show a success message to your buyer
      console_log("Subscription completed", details);
      submitSubscription(data)
    });
  }

  const submitSubscription = async (data) => {
    let url = "/api/payment/paypal/subscription-complete"
    let postData = {
      userId: user['id'],
      plan_id: plan_id,
      orderID: data.orderID,
      subscriptionID: data.subscriptionID,
      itemId: item['id'],
      itemType: itemType
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });
    if (response.ok) {
      let payalResponse = await response.json()
      let newBalance = payalResponse['to_balance']
      if (itemType === 'credit') {
        setItem({ ...item, credit: newBalance })
      }
      else if (itemType === 'escrow') {
        setItem({ ...item, escrow_balance: newBalance })
      }
      setOpen(false);
      dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));

      if (item['deviceType'] === DEVICE_TYPE.SMARTCAR) {
        goSmartcarAuthPage()
      }
      return payalResponse;
    } else {
      alert("Paypal subscription error")
    }
  }

  const goSmartcarAuthPage = async () => {
    const response = await api_call(`/api/smartcar/get-login-url`);
    if (response.ok) {
      const res = await response.json()
      console_log("smartcar res:::", res)
      setSettingPersist('smartcar_auth_source', 'device')
      window.location.href = res.link
      //history.push('/smartcar-device')
    } else {
      alert("Smartcar authentication error")
    }
  }

  const getPaypalPaymentAmount = () => {
    const defaultAmount = 15
    let amount = defaultAmount
    if (item && item.monthly_cost && Number(item.monthly_cost) > 0) {
      amount = Number(item.monthly_cost)
    }
    const amountDecimal = amount.toFixed(2)
    return amountDecimal
  }

  return (
    <>
      <div className='text-center' style={{ marginTop: '3rem', marginRight: '2rem', width: '500px', maxWidth: 'calc(100vw - 160px)' }}>
        <PayPalBtn
          amount={getPaypalPaymentAmount()}
          currency="USD"
          createSubscription={paypalSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
        />
      </div>
    </>
  );
}

const PaypalCreditLogsModal = (props) => {
  // show paypal buttons and credit logs
  const dispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector((state) => state.session.user);
  const { open, setOpen, item, setItem } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)
  const [logItems, setLogItems] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [billingSource, setBillingSource] = useState(item?.billing_source);
  useEffect(() => {
    console.log(`item?.billing_source::::::::`, item?.billing_source)
    setBillingSource(item?.billing_source)
  }, [item?.id]);

  useEffect(() => {
    if (item && item.id) {
      loadPlanId();
    }
  }, [item?.id]);

  const handleCreditModalClose = () => {
    setOpen(false)
  }

  ////////////////////////////////////paypal subscription modal///////////////////////////////////////////
  const [planId, setPlanId] = useState("");
  const [paypalAccessToken, setPaypalAccessToken] = useState("");
  const [paypalSubscriptionDetail, setPaypalSubscriptionDetail] = useState(null);

  const loadPlanId = async () => {
    let planInfo = await getMoovetraxMonthlyPlan()
    console_log("PlanInfo:::", planInfo)
    if (!empty(planInfo)) {
      setPlanId(planInfo['id'])
    }
  }
  const getMoovetraxMonthlyPlan = async () => {
    let planInfo = await createMoovetraxMonthlyPlan();
    return planInfo;
  }
  const createMoovetraxMonthlyPlan = async () => {
    let url = "/api/payment/paypal/create-plan"
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId: item.id, itemType: itemType }),
    });

    if (response.ok) {
      let payalResponse = await response.json()
      if (payalResponse['id']) {
        setItem({ ...item, plan_id: payalResponse['id'] })
      }
      return payalResponse;
    }
    return false;
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const onClosePaypalPopup = async (deviceId) => {
    let url = "/api/devices/close-paypal-banner"
    let postData = {
      deviceId: deviceId
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });

    return true
  }

  const onClickCloseSubscriptionBanner = () => {
    console_log(`onClickCloseSubscriptionBanner:::`, item)
    //await onClosePaypalPopup(item.id)
    closePaypalBanner(item.id)
    handleCreditModalClose()
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState(`Escrow In Use - Device Paypal Suspended`)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    saveBillingSource()
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
    if (billingSource === 'escrow') {
      setBillingSource('paypal')
    }
  }
  const onClickSaveBillingSource = (billing_source) => {
    setBillingSource(billing_source)
    saveBillingSource(billing_source)
  }
  const saveBillingSource = async (billing_source = null) => {
    if (apiLoading) {
      return false
    }
    if (empty(billing_source)) {
      billing_source = billingSource
    }
    var url = `/api/devices/set-billing-source`
    const post_data = {
      device_id: item.id,
      billing_source: billing_source
    }
    setApiLoading(true)
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_data),
    });
    setApiLoading(false)
    if (response.ok) {
      let responseObj = await response.json()
      setItem(responseObj)
      dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
      setUpdateTimestamp(get_utc_timestamp_ms())
      if (billing_source === 'escrow') {
        setOpen(false)
      }
    } else {
      let errorObj = response
      if (errorObj['error']) {
        alert(errorObj['error'])
        setBillingSource(item?.billing_source)
        if (errorObj['escrow_balance_insufficient']) {
          setOpen(false)
          showEscrowLogsModal()
        }
      } else {
        alert("Unknown error")
      }
    }
    setShowConfirmModal(false)
  }

  const [escrowUser, setEscrowUser] = useState();
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);
  const showEscrowLogsModal = async () => {
    setItem(escrowUser)
    setEscrowLogsModalOpen(true)
  }

  const OnClickEscrowBalance = (event) => {
    event.preventDefault()
    setOpen(false)
    showEscrowLogsModal()
  }

  const onClickShowVideo = () => {
    dispatch(layoutActions.showPaymentVideoModal());
  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleCreditModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={modalInnerStyle}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={1500}
              message={toastMessage}
            />
            <div className={classes.paypalBtnBox}>
              {
                ((planId) && !empty(planId)) ? (
                  <>
                    {
                      (parseFloat(item.credit) > MAX_CREDIT_AMOUNT) ? (
                        <div></div>
                      ) : (
                        <div style={{ textAlign: 'right', position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
                          <span onClick={() => onClickCloseSubscriptionBanner()} className={classes.paypalHideBtn} ><VisibilityOffIcon color="action" /></span>
                        </div>
                      )
                    }
                    <div>
                      <Box sx={{ mr: 1 }}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={billingSource || ''}
                            onChange={(event) => onClickSaveBillingSource(event.target.value)}
                            style={{ justifyContent: "flex-start" }}
                            row
                          >
                            <FormControlLabel value="paypal" control={<Radio />} label={
                              <>
                                <PaypalPaymentBox
                                  open={open}
                                  setOpen={setOpen}
                                  item={item}
                                  setItem={setItem}
                                  plan_id={planId}
                                  updateTimestamp={updateTimestamp}
                                  setUpdateTimestamp={setUpdateTimestamp}
                                />
                              </>
                            } />
                            <FormControlLabel value="escrow" control={<Radio />} label={
                              <Box sx={{ textAlign: 'center' }}>
                                <Box>
                                  Pay from Escrow {escrowUser?.id ? <span style={{ cursor: 'pointer', color: '#1366ff' }} onClick={(e) => OnClickEscrowBalance(e)}>({escrowUser?.escrow_balance})</span> : <></>}
                                </Box>
                                {/* <Box>
                                <Button color="primary" variant="contained" size="small" onClick={() => onClickSaveBillingSource()}>Pay Now</Button>
                              </Box> */}
                              </Box>
                            } />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {/* {
                      (billingSource !== item?.billing_source) && (
                        <Box sx={{ mr: 1 }}>
                          <Button color="primary" variant="contained" size="small" onClick={() => onClickSaveBillingSource()}>Apply</Button>
                        </Box>
                      )
                    } */}
                      <Box sx={{ mr: 1 }}>
                        <Typography variant='p'
                          onClick={() => onClickShowVideo()}
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff', display: 'block', paddingTop: '8px', paddingBottom: '8px' }}
                        >
                          Video
                        </Typography>
                      </Box>

                    </div>
                  </>
                ) : (<></>)
              }
            </div>

            <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Balance:&nbsp;<span>{item?.credit || '0.00'}</span>&nbsp;
              </Typography>

              <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography className='pe-5 py-1' id="modal-modal-title1" variant="h7" component="h6">
                  Start:&nbsp;<span>{moment(item?.createdAt).format('LL')}</span>
                </Typography>

                <Typography className='pe-3 py-1' id="modal-modal-title2" variant="h7" component="h6">
                  Billed on: {(logItems.length > 0) && <span>&nbsp;{item?.billed_date}</span>}
                </Typography>
              </Box>
            </Box>

            <Typography id="creditLogTableTitle" className='mt-3'>
              Credit Logs
            </Typography>
            <div className='mt-3'>
              <CreditLogsTable
                open={open}
                setOpen={setOpen}
                selectedDevice={item}
                setDevice={setItem}
                updateTimestamp={updateTimestamp}
                setUpdateTimestamp={setUpdateTimestamp}
                paypalSubscriptionDetail={paypalSubscriptionDetail}
                setPaypalSubscriptionDetail={setPaypalSubscriptionDetail}
                logItems={logItems}
                setLogItems={setLogItems}
                escrowUser={escrowUser}
                setEscrowUser={setEscrowUser}
              />
            </div>
          </Box>
        </Box>
      </Modal>

      <>
        {
          (item && item.id) ? (
            <>
              <EscrowLogsModal
                open={escrowLogsModalOpen}
                setOpen={setEscrowLogsModalOpen}
                item={item}
                setItem={setItem}
                modalType={user.administrator ? 'user' : 'self'}
              />
            </>
          ) : (<></>)
        }
      </>
    </>
  );
};

export default PaypalCreditLogsModal;
